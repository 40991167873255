<template>
  <div style="display: flex">
    <div v-if="imageUrl.length != 0">
      <div style="position: relative; display: inline-block; margin-right: 10px" class="img-box"
        v-for="(item, index) in imageUrl" :key="index">
        <el-image fit="contain" class="avatar" :src="item">
          <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4" style="
              width: 50px;
              height: 50px;
              margin-left: 16px;
              margin-top: 14px;
            ">
            <path
              d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
          </svg></el-image>
        <i class="el-icon-error" style="
            position: absolute;
            right: 4px;
            top: 4px;
            z-index: 1;
            font-size: 20px;
            color: #1791f2;
            cursor: pointer;
          " @click="delImg(index)"></i>
      </div>
    </div>
    <el-upload v-if="imageUrl.length < imgLength" class="avatar-uploader" action="action" :show-file-list="false"
      :headers="headers" :on-success="handleAvatarSuccess" :on-error="handleAvatarError" :http-request="handleRequest"
      name="imagelist" :accept="accept">
      <!-- <i class="el-icon-plus avatar-uploader-icon" slot="trigger"></i> -->
      <div class="avatar-uploader-icon" style="display: flex; align-items: center">
        <div style="
            border-radius: 50%;
            border: 1px solid #1791f2;
            width: 24px;
            height: 24px;
            margin: 0 auto;
            line-height: 20px;
            color: #1791f2;
            font-size: 22px;
          ">
          +
        </div>
      </div>
    </el-upload>
  </div>
</template>
<script>
  import { getSAuth, getSchool } from "@/utils/auth.js";
  import env from "@/settings/env.js";
  import { uploadFilesVideo, deleteVideo } from "@/api/common.js";
  export default {
    name: "UploadFiles",
    data() {
      return {
        headers: {
          Authorization: getSAuth(),
          school: getSchool(),
        },
        // imageUrl: [],
        action: env.apiUrlPrefix + "api/upload/files",
      };
    },
    props: {
      imgLength: { type: Number, default: 1 },
      accept: { type: String, default: ".jpg,.jpeg,.png" },
      imageUrl: { type: Array },
      video: { type: Array },
      ids: { type: Array },
    },
    mounted() {

      if (this.$props.accept == ".mp4") {
        this.action = env.apiUrlPrefix + "api/upload/video";
      }
    },
    methods: {
      submitUpload() {
        this.$refs.upload.submit();
      },

      handleRequest(res) {
        if (res.file.type != "video/mp4") {
          this.$message.error("文件格式错误");
          return false;
        }
        if (res.file.size > "31457280 ") {
          this.$message.error("视频大小超过限制，最大支持30MB");
          return false;
        }

        let url = URL.createObjectURL(res.file);
        let video = document.createElement('video');
        video.src = url;
        let _this = this;
        video.addEventListener("loadedmetadata", function () {
          if (video.duration < 10 || video.duration > 60) {
            _this.$message.error("视频时长不符合限制，时长应为10s-60s内");
          } else {
            let formData = new FormData();
            formData.append("imagelist", res.file);
            uploadFilesVideo(formData).then((res) => {
              _this.handleAvatarSuccess(res);
            });
          }
        });
      },
      handleAvatarSuccess(res) {
        if (res.code == 1) {
          this.$message.success("上传成功");
          // this.$props.imageUrl.push(res.data.url);
          if (this.$props.accept == ".mp4") {
            this.$props.imageUrl.push(res.data.video_img);
            this.$props.video.push(res.data.url);
            this.$props.ids.push(res.data.id);
          }
        } else {
          this.$message.error(res.message);
        }
      },
      handleAvatarError(err) {
        console.log(err);
      },
      delImg(index) {
        if (this.$props.accept == ".mp4") {
          // deleteVideo({
          //   file_id: this.$props.ids[index],
          // }).then((res) => {

          // if (res.code == 1) {
          this.$props.video.splice(index, 1);
          this.$props.imageUrl.splice(index, 1);
          this.$props.ids.splice(index, 1);
          //   }
          // });
        }
      },
    },
  };
</script>
<style lang="less">
  .avatar-uploader .el-upload {
    border: 1px dashed #1791f2;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #1791f2;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #1791f2;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .avatar-uploader-icon:hover {
    color: #1791f2;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 4px;
    border: 1px dashed #d9d9d9;

    .el-icon-error {
      display: none;
    }
  }

  .avatar {
    border-color: #1791f2;
  }

  .img-box:hover .el-icon-error {
    display: block;
  }
</style>