<template>
    <div class="category-cell">
        <el-input style="width: 400px" v-model="categoryName" size="medium" placeholder="类目名称" prefix-icon="el-icon-search"
            clearable @clear="getCategory" @keydown.native="searchCategory()"></el-input>
        <div>
            <el-cascader-panel style="
              background: #fff;
              margin-top: 20px;
              min-height: 300px;
              max-height: 400px;
            " v-model="categoryData" @change="change" :options="options" :props="configuration"
                ref="panel"></el-cascader-panel>
        </div>
        <div style="    display: flex;
    justify-content: space-between;    align-items: center;">
            <div style="font-size: 14px; margin-top: 20px">
                正在选择的：<span :style="{ color: areChoosing ? '#EE4D2D' : '#999999' }">{{ areChoosing ? areChoosing :
                    "没有选择任何类别" }}</span>
            </div>
            <div style="padding-top:20px">
                <el-button size="medium" @click="close">取消</el-button>
                <el-button size="medium" type="primary" @click="handleClick">确认</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { categoryInfo } from "@/api/common.js";
export default {
    props: ['info'],
    data() {
        return {
            goodsName: "",
            categoryData: "",
            categoryName: "",
            configuration: {
                disabled: false,
                value: "category_id",
                label: "name",
                children: "list",
            },
            areChoosing: false,
            options: [],
            visible: false,
            addgoodsType: 1,
        };
    },
    created() {


        this.getCategory();
        if (sessionStorage.ADD_GOODS_TYPE) {
            this.addgoodsType = sessionStorage.ADD_GOODS_TYPE;

            this.$router.options.routes.forEach((item) => {
                if (item.name == "myGoodsz") {
                    item.children.forEach((val) => {
                        if (val.name == "addGoods") {
                            val.meta.bread[1].name =
                                this.addgoodsType == 1 ? "全球商品" : "我的商品";
                            val.meta.bread[1].path =
                                this.addgoodsType == 1 ? "globalGoods" : "myGoods";
                        }
                        if (val.name == "addGoodsInfo") {
                            val.meta.bread[1].name =
                                this.addgoodsType == 1 ? "全球商品" : "我的商品";
                            val.meta.bread[1].path =
                                this.addgoodsType == 1 ? "globalGoods" : "myGoods";
                        }
                    });
                }
            });
        }
    },
    mounted() {
        // console.log(this.$props.info)
    },
    methods: {
        change(value) {
            let data = "";
            if (value[0]) {
                this.options.forEach((item) => {
                    if (item.category_id == value[0]) {
                        this.areChoosing = item.name;
                        data = item;
                    }
                });
            }
            if (value[1]) {
                data.list.forEach((item) => {
                    if (item.category_id == value[1]) {
                        this.areChoosing = this.areChoosing + ">" + item.name;
                        data = item;
                    }
                });
            }
            if (value[2]) {
                data.list.forEach((item) => {
                    if (item.category_id == value[2]) {
                        this.areChoosing = this.areChoosing + ">" + item.name;
                    }
                });
            }
        },
        getCategory() {
            categoryInfo({
                keyword: this.categoryName,
            }).then((res) => {
                this.options = res.data;
            });
        },
        searchCategory(e) {
            let evt = window.event || e;
            if (evt.keyCode == 13) {
                this.categoryData = "";
                this.areChoosing = false;
                this.getCategory();
            }
        },
        blur() {
            if (this.goodsName != "") {
                if (this.goodsName.length < 10) {
                    this.visible = true;

                    this.$refs.Name.focus();
                } else {
                    this.visible = false;
                }
            } else {
                this.visible = false;
            }
        },
        toNext() {
            if (this.goodsName == "") {
                this.$message.error("请填写商品名称");
                return false;
            }
            sessionStorage.GOODS = JSON.stringify({
                goods_id: this.categoryData.join(","),
                title: this.goodsName,
                areChoosing: this.areChoosing,
            });
            this.$router.replace("addGoodsInfo");
        },

        handleClick() {
            let obj = {
                areChoosing: this.areChoosing,
                categoryData: this.categoryData.join(',')
            }
            this.$emit('setFl', obj)
        },
        close() {
            this.$emit('handleClose')
        }
    },
};
</script>
<style>
.category-cell {
    background: #fafafa;
    padding: 20px;
    margin-top: 30px;

    .el-input__inner {
        border-radius: 30px;
    }

    .el-cascader-menu {
        min-width: 30%;
    }

    .el-cascader-menu__wrap {
        height: 106%;
    }
}
</style>